export class RouteConstants {
  public static readonly home: string = "home";
  public static readonly ruleSetup: string = "rule-setup";
  public static readonly fileUpload: string = "file-upload";
  public static readonly processingUpdate: string = "processing-update";
  public static readonly tenentAppDashboard: string = "tenant-app-dashboard";
  public static readonly missingEnrichment: string = "missing-enrichment";
  public static readonly fileSubmissionStatus: string =
    "file-submission-status";
  public static readonly workflowActions: string = "workflows/actions";
  public static readonly workflowImportExport: string =
    "workflows/import-export";
  public static readonly dataDelivery: string = "reports/data-delivery";
  public static readonly levelOne: string = "configuration/level-one";
  public static readonly levelTwo: string = "configuration/level-two";
  public static readonly levelThree: string = "configuration/level-three";
  public static readonly finDataSource: string = "configuration/fds";
  public static readonly comDataSource: string = "configuration/cds";
  public static readonly workflow: string = "admin/workflow";
  public static readonly fileExplorer: string = "admin/file-explorer";
  public static readonly enrichmentUpload: string = "admin/enrichment-upload";
  public static readonly enrichmentUploadHistory: string =
    "admin/enrichment-upload-history";
  public static readonly maintainEnrichment: string =
    "admin/maintain-enrichment";
  public static readonly maintainUserDetails: string =
    "admin/maintain-user-details";
  public static readonly maintainReportTypes: string =
    "admin/maintain-report-types";
  public static readonly maintainEmailTemplates: string =
    "admin/maintain-email-templates";
  public static readonly maintainReportingSchedule: string =
    "admin/maintain-reporting-schedule";
  public static readonly MissingEnrichmentComponent: string =
    "missing-enrichment";
  public static readonly MissingEnrichmentAdminComponent: string =
    "workflow/missing-enrich-ad";
  public static readonly MissingEnrichmentRmanagerComponent: string =
    "workflow/missing-enrich-rm";
  public static readonly ClientOffBoardingComponent: string =
    "admin/client-data-cleanup";
  public static readonly SelfServiceValidationApprovalComponent: string =
    "validation-approval";
    public static CoaAssistantComponent: string = 'coa-assistant';
    public static CoaDraftHistoryComponent: string = 'coa-draft-history';
    public static CoaDraftDetailComponent: string = 'coa-draft-detail';
}
