import { Component, NgZone } from "@angular/core";
import { environment } from "../environments/environment";

import {
  CommonConfirmationDialog,
  ConfirmationDialogData,
} from "./common-dialog/common.confirmation.dialog.component";
import { AuthService } from "./shared/auth.service";
import * as _ from "lodash";
import { LogService } from "./shared/app-loggers/app-log.service";
import { MatDialog } from "@angular/material/dialog";
import { Adal6Service } from "adal-angular6";
import { RouterOutlet } from "@angular/router";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { LeftNavComponent } from "./left-nav/left-nav.component";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonUtilityService } from "./shared/common.utility.service";
const config = {
  instance: environment.instance,
  tenant: environment.tenant,
  clientId: environment.clientId,
  redirectUri: window.location.origin + "/",
  postLogoutRedirectUri: window.location.origin + "/",
  api_version: "2.0",
  extraQueryParameter:
    "scope=openid&prompt=login" + "&p=" + environment.authnPolicy,
  resetPassword:
    "scope=openid&prompt=login" + "&p=" + environment.pwdResetPolicy,
};

const MINUTES_UNITL_AUTO_LOGOUT = environment.minutesUntilAutoLogout; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = "lastAction";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],

  providers: [
    CommonConfirmationDialog,
    RouterOutlet,
    LeftNavComponent,
    HeaderComponent,
    FooterComponent,
  ],
})
export class AppComponent {
  title = "GIA-Data File Delivery";
  lastAction: number;
  loggedOutPopupCall: boolean = false;
  authToken: string;
  private expirationKeyNumber: any;

  constructor(
    private adalService: Adal6Service,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private auth: AuthService,
    private logger: LogService,
    private spinner: NgxSpinnerService,
    private cutlsrv: CommonUtilityService
  ) {
    this.spinner.show();
    this.expirationKeyNumber = Number(
      sessionStorage.getItem(`adal.expiration.key${environment.clientId}`)
    );
    let error_description: any = this.cutlsrv.getUrlParameter(
      "error_description",
      window.location.href
    );

    if (error_description.includes("AADB2C90118")) {
      window.location.href = this.getPasswordResetUrl();
      this.spinner.hide();
      return null;
    }

    /* if (!this.adalService.userInfo.authenticated) {
      console.log('Not authenticated!');
    } */
    this.adalService.init(config);
    // Handle callback if this is a redirect from Azure
    this.adalService.handleWindowCallback();
    /* if (this.adalService.userInfo.authenticated) {
      console.log('Authenticated!');
    } */

    this.lastAction = Date.now();
    this.checkAutoLogOut();
    this.initAutoLogOutListener();
    this.initAutoLogOutInterval();
  }
  getPasswordResetUrl() {
    return `${environment.instance}${
      environment.tenant
    }/oauth2/v2.0/authorize?p=${environment.pwdResetPolicy.toUpperCase()}&client_id=${
      environment.clientId
    }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
      config.redirectUri
    )}&scope=openid&response_type=id_token&prompt=login`;
  }

  initAutoLogOutListener() {
    
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.resetAutoLogOut());
      document.addEventListener("keypress", () => this.resetAutoLogOut());
    });
  }

  initAutoLogOutInterval() {
    //this.logger.info("Initilizing auto logout interval.");
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        //this.paramLoggingSvc.logDebug("checking ....");
        this.checkAutoLogOut();
      }, CHECK_INTERVALL);
    });
  }

  resetAutoLogOut() {
    //this.logger.info("Resettting auto logout");
    this.lastAction = Date.now();
  }

  checkAutoLogOut() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (
        (isTimeout &&
          this.auth.CurrentUser.isAuthenticated &&
          !this.loggedOutPopupCall) ||
        (!_.isEmpty(this.expirationKeyNumber) &&
          this.expirationKeyNumber > 0 &&
          Date.now() / 1000 >= this.expirationKeyNumber - 60)
      ) {
        this.loggedOutPopupCall = true;
        this.logger.warn(
          "The system is going to log then out due to inactivity."
        );
        const dialogRef = this.dialog.open(CommonConfirmationDialog, {
          width: "650px",
          data: <ConfirmationDialogData>{
            title: `Inactivity Logout`,
            description: `The system is going to log then out due to inactivity.`,
            dialogData: "InactivityLogout",
          },
        });

        dialogRef
          .afterClosed()
          .subscribe((confirmation: ConfirmationDialogData) => {
            if (confirmation.isOkClick) {
              this.logger.warn("Resetting auto logout.");
              this.resetAutoLogOut();
              this.loggedOutPopupCall = false;
            } else {
              this.logger.warn("User has been logout from sso session.");
              this.adalService.login();
            }
          });
      }
    });
  }
}
