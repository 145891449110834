import { Component, Inject, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

export interface AlertDialogData {
  title: string;
  description: string;
  isCancelClick: boolean;
  isOkClick: boolean;
  dialogData: any;
}

@Component({
  selector: "common-alert-dialog",
  styleUrls: ["./common.alert.dialog.component.scss"],
  templateUrl: "./common.alert.dialog.component.html",
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatIconModule, MatButtonModule],
})
export class CommonAlertDialog {
  constructor(
    private dialogRef: MatDialogRef<CommonAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private router: Router
  ) {
    this.router.events.subscribe((val) => {
      if (!this.dialogRef) return;
      this.dialogRef.close();
    });
  }
  ngOnInit() {
    console.log(" ");
  }

  private setOkCancelStatus(isOkClicked: boolean) {
    this.data.isOkClick = isOkClicked;
    this.data.isCancelClick = !isOkClicked;
  }
  onNoClick(): void {
    this.setOkCancelStatus(false);
    this.dialogRef.close(this.data);
  }

  onOkClick(): void {
    this.setOkCancelStatus(true);
    this.dialogRef.close(this.data);
  }

  /*

    We can then use the dialog reference to subscribe to the afterClosed()
    observable, which will emit a value containing the output data passed to
    dialogRef.close().

    dialogRef.afterClosed().subscribe(
        data => console.log("Dialog output:", data)
    );
    */
}
